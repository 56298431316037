import AccordionComponent from '@wix/thunderbolt-elements/src/components/Accordion/viewer/Accordion';
import AccordionController from '@wix/thunderbolt-elements/src/components/Accordion/viewer/Accordion.controller';


const Accordion = {
  component: AccordionComponent,
  controller: AccordionController
};


export const components = {
  ['Accordion']: Accordion
};

